




































































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class extends Vue {
  hbgsrc: string = require("@/assets/enviroment/img/bg.png");
  left1: string = require("@/assets/enviroment/img/left1.png");
  right1: string = require("@/assets/enviroment/img/right1.png");
  left2: string = require("@/assets/enviroment/img/left2.png");
  right2: string = require("@/assets/enviroment/img/right2.png");
}
